<template>
  <div>
    <div ref="videoContainer" class="mx-auto d-block text-center">
      <video ref="video" width="100%" height="100%" autoplay></video>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MainService } from "@/services/main.service";
import {ref, onMounted, onBeforeUnmount} from 'vue';

export interface CameraProps {
  workId ?: number
}

const {workId} = defineProps<CameraProps>();

const videoContainer = ref<any>(null);
const video = ref<HTMLVideoElement>();

const mediaStream = ref<MediaStream | null>();

const setupWebcam = async () => {
  try {
    mediaStream.value = await navigator.mediaDevices.getUserMedia({video: true});

    if (video.value) {
      video.value.srcObject = mediaStream.value;
      video.value.addEventListener('loadedmetadata', () => video.value?.play())
    } 
  } catch (error) {
    console.error('Error accessing webcam: ', error);
  }
};

function uploadCameraFrame() {
  if (video.value == undefined) return
  if(workId == undefined) return;
  const canvas = document.createElement('canvas');
  canvas.width = 320
  canvas.height = 240

  const context = canvas.getContext('2d');
  if (context == undefined) return
  context.drawImage(video.value, 0, 0, canvas.width, canvas.height);

  canvas.toBlob(blob => {
    if (blob == undefined) return
    const file = new File([blob], "image.jpg", {type: "image/jpeg"});
    MainService.uploadImage(workId, file)
        .then(rsp => console.log("Image uploaded"))
        .catch(e => console.log("Image upload failed"))
  }, "image/jpeg", 0.7);
}

const imageInterval = setInterval(() => uploadCameraFrame(), 1000)

onMounted(() => {
  setupWebcam();
});

onBeforeUnmount(() => {
  if (mediaStream.value) {
    mediaStream.value.getTracks().forEach((track) => track.stop());
  }
  clearInterval(imageInterval)
});
</script>  